import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import {
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useSnackbar } from "notistack";

const Img = styled("img")({
  maxWidth: "100%",
  minWidth: "100%",
});

export default function SignUp() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatpassword, setRepeatPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const isPasswordValid = (password) => {
    // Here are some best practices for passwords:
    // - At least 8 characters long
    // - Contains at least one digit
    // - Contains at least one lowercase letter
    // - Contains at least one uppercase letter
    // - Contains at least one special character
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
    return regex.test(password);
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!isPasswordValid(password)) {
        enqueueSnackbar("Password doesn't meet the requirements", {
          variant: "error",
        });
        setIsLoading(false);
        return;
      }
      createUserWithEmailAndPassword(auth, email, password).then(
        (userCredential) => {
          const user = userCredential.user;
          sendEmailVerification(user).then(() => {
            enqueueSnackbar(
              "We sent you a verification email. Please check your inbox.",
              { variant: "success" }
            );
            enqueueSnackbar("Redirecting you to the login page...", {
              variant: "success",
            });
            setIsLoading(false);
            navigate("/login");
          });
        }
      );
      setIsLoading(false);
    } catch {
      setIsLoading(false);
      enqueueSnackbar("Error signing up with email and password", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          // height: "100vh",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
            height: "400px",
          }}
        >
          <Box>
            <Img
              alt="Hyper Cloud Automation Logo"
              src="../xoogify_Blue-Logo-B_croped.png"
              data-allow-toggle
              sx={{
                opacity: process.env.REACT_APP_IS_XOOGIFY === "true" ? 1 : 0,
              }}
            ></Img>
          </Box>
          <Box mt={2}>
            <Typography component="h1" variant="h5">
              Sign Up
            </Typography>
          </Box>
          <Box mt={3} width="100%">
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mt={2} width="100%">
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={2}>
              <Typography variant="body2" color="textSecondary">
                * Password must be at least 8 characters long and contain at
                least one digit, one lowercase letter, one uppercase letter, and
                one special character
              </Typography>
            </Box>
          </Box>
          <Box mt={2} width="100%">
            <TextField
              name="repeatpassword"
              label="Repeat Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={repeatpassword}
              onChange={(e) => setRepeatPassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mt={2}>
            <Button variant="contained" color="primary" onClick={handleSignUp}>
              Sign Up
            </Button>
          </Box>
        </Box>
      </Grid>

      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>
    </>
  );
}
