import React, { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import GoogleButton from "react-google-button";
import { styled } from "@mui/material/styles";

// This allows us to use sx={} instead of className={} for the img tag
const Img = styled("img")({});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered by  MarkoDevOps© "}
      <Link color="inherit" href="https://markodevops.com/" underline="hover">
        markodevops.com
      </Link>
      {" 2023"}
    </Typography>
  );
}

export default function SignInSide() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const {
    currentUser,
    organizations,
    signIn,
    handleSignInUsernamePassword,
    isLoadingLogin,
    setIsLoadingLogin,
  } = useAuth();
  let history = useNavigate();

  useEffect(() => {
    if (currentUser != null && organizations.length > 0) {
      console.log("currentUser", currentUser);
      console.log("organizations", organizations);
      setIsLoadingLogin(false);
      history("/dashboard");
    }
    // eslint-disable-next-line
  }, [currentUser, organizations]);

  const handleSignIn = async () => {
    try {
      await signIn();
    } catch {
      console.log("error");
    }
  };

  const handleResetPassword = async (e) => {
    alert("Not implemented yet");
  };

  return (
    <>
      <Grid
        container
        component="main"
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CssBaseline />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "400px",
            height: "400px",
            mt: 4,
          }}
        >
          <Box>
            <Img
              src="../xoogify_Blue-Logo-B_croped.png"
              alt="Hyper Cloud Automation Portal"
              data-allow-toggle
              sx={{
                display: "flex",
                width: "100%",
                height: "100%",
                opacity: process.env.REACT_APP_IS_XOOGIFY === "true" ? 1 : 0,
              }}
            ></Img>
          </Box>
          <Box mt={4}>
            <Typography component="h1" variant="body1" align="center">
              Power your cloud with Hyper Cloud Automation
            </Typography>
          </Box>
          <Box mt={3} width="100%">
            <TextField
              name="email"
              label="Email"
              variant="outlined"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Box>
          <Box mt={2} width="100%">
            <TextField
              name="password"
              label="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box mt={1}>
              <Typography color="primary" variant="body2">
                <Link href="#" onClick={(e) => handleResetPassword(e)}>
                  Forgot Password?
                </Link>
              </Typography>
            </Box>
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent={"center"}
            flexDirection={"column"}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={(e) => handleSignInUsernamePassword(e, email, password)}
            >
              Sign In
            </Button>
            <Box mt={1}>
              <Typography color="primary" variant="body2">
                {"Don't have an account? "}
                <Link onClick={() => history("/signup")}>Sign up</Link>
              </Typography>
            </Box>
          </Box>
          <Box mt={2}>
            <Typography color="textSecondary" variant="body2">
              Or
            </Typography>
          </Box>
          <Box
            mt={2}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Box justifyContent="center" display="flex">
              <GoogleButton type="light" onClick={handleSignIn} />
            </Box>
            <Box mt={1}>
              <Typography
                color="textSecondary"
                variant="body2"
                align="center"
                width="240px" // This is the width of the Google button
              >
                You don't need to sign up if you have a Google account. Just
                login.
              </Typography>
            </Box>
          </Box>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Box>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoadingLogin}
        onClick={() => setIsLoadingLogin(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
