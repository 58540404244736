import { React, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

//Material-UI
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Popover from "@mui/material/Popover";
import DialogActions from "@mui/material/DialogActions";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import Badge from "@mui/material/Badge";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import NotificationsIcon from "@mui/icons-material/Notifications";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

//UserDefined
import { useAuth } from "../contexts/AuthContext";
import { fetchOrganizations } from "../utils/db";
import Profile from "./Profile";
import Organizations from "./Organization";
import NavMenu from "./NavMenu";
import Environments from "./Environments";
import Billing from "./Billing";
import Overview from "./Overview";
import Playground from "./Playground";
import Deployments from "./Deployments";
import ArchitectureTemplateBuilder from "./ArchitectureTemplateBuilder";
import { Route, Routes } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import { apiCall } from "../utils/api";
import { useSnackbar } from "notistack";

// This allows us to use sx={} instead of className={} for the img tag
const Img = styled("img")({});

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered by MarkoDevOps© "}
      <Link color="inherit" href="https://markodevops.com/" underline="hover">
        markodevops.com
      </Link>
      {" 2023"}
    </Typography>
  );
}

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function Dashboard() {
  // Used for Sign Out menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const [open, setOpen] = useState(true);
  const [invitations, setInvitations] = useState([]);
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  // User defined
  const { currentUser, signOutFirebase, organizations, setOrganizations } =
    useAuth();

  const openAvatarMenu = Boolean(anchorEl);
  const openAvatarMenuId = openAvatarMenu ? "simple-popover" : undefined;

  const openNotifications = Boolean(notificationsAnchorEl);
  const openNotificationsId = openNotifications
    ? "not-so-simple-popover"
    : undefined;

  let history = useNavigate();

  const handleAvatarButtonClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarButtonClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async (event) => {
    event.preventDefault();
    console.log("SignOut");
    await signOutFirebase();
    history("/login");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!currentUser) {
      history("/");
      return;
    }
    getOrganizations();
    const pathname = window.location.pathname;
    if (
      [
        "/dashboard/billing",
        "/dashboard/overview",
        "/dashboard/organizations",
        "/dashboard/environments",
        "/dashboard/profile",
        "/dashboard/deployments",
        "/dashboard/architectures",
      ].includes(pathname)
    ) {
      history(pathname);
    } else {
      // Ensure that we load something into the dashboard
      history("/dashboard/overview");
    }
    // eslint-disable-next-line
  }, []);

  const getOrganizations = async () => {
    if (organizations.length === 0) {
      const orgs = await fetchOrganizations(currentUser.uid);
      setOrganizations(orgs);
    } else {
      console.log(`organizations ${organizations}`);
      console.log(organizations);
    }
  };

  const handleClickNotificationsIcon = (event) => {
    event.preventDefault();
    setNotificationsAnchorEl(event.currentTarget);
  };

  const handleCloseNotifiations = () => {
    setNotificationsAnchorEl(null);
  };

  const getInvitations = async () => {
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      setInvitations(inv_response);
    }
  };

  const clickOnNotificationItem = () => {
    history("/dashboard/profile");
    setNotificationsAnchorEl(null);
  };

  useEffect(() => {
    getInvitations();
    // eslint-disable-next-line
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Hyper Cloud Automation
          </Typography>
          <Tooltip title="Help pages">
            <IconButton
              color="inherit"
              onClick={(e) => {
                e.preventDefault();
                window.open("https://docs.markodevops.com", "_blank");
              }}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton color="inherit" onClick={handleClickNotificationsIcon}>
              <Badge
                badgeContent={invitations ? invitations.length : 0}
                color="secondary"
              >
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Tooltip>
          <Popover
            id={openNotificationsId}
            open={openNotifications}
            anchorEl={notificationsAnchorEl}
            onClose={handleCloseNotifiations}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List
              sx={{
                width: "100%",
                maxWidth: 480,
                backgroundColor: (theme) => theme.palette.background.paper,
              }}
            >
              {invitations.map((invitation, index) => (
                <ListItemButton
                  onClick={() => clickOnNotificationItem()}
                  key={`notif-${index}`}
                >
                  <ListItem key={index}>
                    <ListItemText
                      key={`notif-${index}`}
                      secondary={`From: ${invitation.inviter}`}
                      primary={`Join ${invitation.organization_name} `}
                    />
                  </ListItem>
                </ListItemButton>
              ))}
            </List>
          </Popover>
          <IconButton
            color="inherit"
            onClick={handleAvatarButtonClick}
            size="large"
          >
            <Avatar src={currentUser.photoURL} />
          </IconButton>
          <Popover
            id={openAvatarMenuId}
            open={openAvatarMenu}
            anchorEl={anchorEl}
            onClose={handleAvatarButtonClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Box
              sx={{ padding: (theme) => theme.spacing(2) }}
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="300"
            >
              <Box
                sx={{ padding: (theme) => theme.spacing(2) }}
                display="flex"
                alignItems="center"
                padding="2"
              >
                <Avatar src={currentUser.photoURL} />
              </Box>
              <Box
                sx={{ padding: (theme) => theme.spacing(2) }}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <Typography variant="h6" component="h2">
                  {currentUser.displayName}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {currentUser.email}
                </Typography>
              </Box>
              <Box
                sx={{ padding: (theme) => theme.spacing(2) }}
                display="flex"
                alignItems="center"
                flexDirection="column"
              >
                <DialogActions>
                  <Button
                    sx={{ padding: (theme) => theme.spacing(2) }}
                    variant="contained"
                    color="primary"
                    style={{ height: 40 }}
                    onClick={handleSignOut}
                  >
                    Sign Out
                  </Button>
                </DialogActions>
              </Box>
            </Box>
          </Popover>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Img
              src={
                theme.palette.mode === "dark"
                  ? "../xoogify_Blue-Logo-C_croped.png"
                  : "../xoogify_Blue-Logo-B_croped.png"
              }
              alt="Hyper Cloud Automation Portal"
              data-allow-toggle
              sx={{
                display: "flex",
                width: drawerWidth,
                height: "100%",
                opacity: process.env.REACT_APP_IS_XOOGIFY === "true" ? 1 : 0,
              }}
            ></Img>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <NavMenu open={open} />
        <Divider />
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 2 }}
      >
        <DrawerHeader />
        <Routes>
          <Route path="profile" element={<Profile />} />
          <Route
            path="organizations"
            element={<Organizations />}
            props={{ organizations: organizations }}
          />
          <Route path="environments" exact element={<Environments />} />
          <Route path="billing" element={<Billing />} />
          <Route path="overview" element={<Overview />} />
          <Route path="playground" element={<Playground />} />
          <Route path="deployments" element={<Deployments />} />
          <Route
            path="architectures"
            element={<ArchitectureTemplateBuilder />}
          />
        </Routes>
        <Box pt={4}>
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
}
