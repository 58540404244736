import { React, useState, forwardRef, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";
import {
  Toolbar as PFToolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import "@patternfly/react-core/dist/styles/base.css";
import "./css/logviewer.css";
import { useSnackbar } from "notistack";
import { useAuth } from "../contexts/AuthContext";
import { fetchJobs } from "../utils/db";
import { apiCall } from "../utils/api";
import { fetchOrganization } from "../utils/db";

const columns = [
  { id: "id", label: "Build ID", minWidth: 170 },
  { id: "org_id", label: "Organization", minWidth: 100 },
  {
    id: "arch_name",
    label: "Environment",
    minWidth: 100,
    align: "right",
  },
  {
    id: "node_id",
    label: "Module Type",
    minWidth: 100,
    align: "left",
  },
  {
    id: "module_name",
    label: "Module Name",
    minWidth: 100,
    align: "left",
  },
  {
    id: "user_id",
    label: "User",
    minWidth: 100,
    align: "left",
  },
  {
    id: "tf_command",
    label: "Action",
    minWidth: 50,
    align: "right",
  },
  {
    id: "status",
    label: "Status",
    minWidth: 80,
    align: "right",
  },
];

/*
 * This hook is made to fetch the jobs and the modules statuses
 * from the API and store them in the state. This is necessary in order
 * to prevent React from giving the stuipid error that organizations variable
 * is not part of the dependencies array of the useEffect hook. The same applied for
 * getJobs and getModulesStatuses functions.
 * When we need to refresh the data, we just need to increment the refreshTrigger variable
 */
const useDeploymentsDataFetching = (organizations) => {
  const [orgs] = useState(organizations);
  const [isLoading, setIsLoading] = useState(false);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const getJobsAndStatuses = async () => {
      setIsLoading(true);

      let inProgress = [];
      for (let i = 0; i < orgs.length; i++) {
        let org = await fetchOrganization(orgs[i].id);
        if ("current_job" in org) {
          if (Object.keys(org.current_job).length !== 0) {
            inProgress.push(org.current_job);
          }
        }
      }

      let allRows = [];
      for (let i = 0; i < orgs.length; i++) {
        let dataRows = await fetchJobs(orgs[i].id);
        allRows = [...allRows, ...dataRows];
      }
      inProgress.forEach((job) => {
        if (!job.build_id) {
          allRows = [
            {
              id: "WAITING FOR BUILD INFO",
              arch_name: "",
              type: "",
              name: "",
              tf_command: "",
              status: "WAITING FOR BUILD INFO",
              created: { seconds: 0 },
            },
            ...allRows,
          ];
        }
      });
      allRows.sort((a, b) => {
        return b.created.seconds - a.created.seconds;
      });
      setRows(allRows);
      setIsLoading(false);
    };
    getJobsAndStatuses();
  }, [orgs, refreshTrigger]);
  return { isLoading, rows, refreshTrigger, setRefreshTrigger };
};

export default function Deployments() {
  const { organizations, currentUser } = useAuth();
  const [page, setPage] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [openLogViewer, setOpenLogViewer] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [log, setLog] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, rows, refreshTrigger, setRefreshTrigger } =
    useDeploymentsDataFetching(organizations);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const refresh = async () => {
    let a = refreshTrigger;
    setRefreshTrigger(a + 1);
    return;
  };

  const tableCellClickHandler = async (row) => {
    setSelectedRow(row);
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/logs/${row.org_id}/${row.id}/${row.created.seconds}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error fetching logs", { variant: "error" });
    }
    let a = await res.text();
    setLog(a);
    setOpenBackdrop(false);
    setOpenLogViewer(true);
  };

  const handleCloseLogViewer = () => {
    setOpenLogViewer(false);
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <Button onClick={refresh} variant="outlined" sx={{ m: 1 }}>
        Refresh
      </Button>
      <TableContainer sx={{ maxHeight: 700 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <FormatedTableRow
                    organization={organizations.find(
                      (org) => org.id === row.org_id
                    )}
                    row={row}
                    index={index}
                    key={index}
                    tableCellClickHandler={tableCellClickHandler}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <LogViewerComponent
        open={openLogViewer}
        handleClose={handleCloseLogViewer}
        log={log}
        currentRow={selectedRow}
        currentUser={currentUser}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </Paper>
  );
}

function FormatedTableRow({ organization, row, index, tableCellClickHandler }) {
  const getUserEmail = (organization, user_id) => {
    if (organization?.admin?.id === user_id) {
      return organization.admin.email;
    }
    let user = organization?.team?.editors.find((user) => user.id === user_id);
    if (user?.email) {
      return user.email;
    }
    return user_id;
  };
  const getEnvironmentName = (row) => {
    if (row?.arch_name) {
      return row.arch_name;
    }
    if (row?.env_name) {
      return row.env_name;
    }
    return "";
  };

  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
      <TableCell
        key={`${row.id}-0`}
        align="left"
        onClick={(e) => tableCellClickHandler(row)}
      >
        {row.id}
        {row.id === "WAITING FOR BUILD INFO" && <CircularProgress />}
      </TableCell>
      <TableCell key={`${row.id}-1`} align="left">
        {row.org_name}
      </TableCell>
      <TableCell key={`${row.id}-2`} align="right">
        {getEnvironmentName(row)}
      </TableCell>
      <TableCell key={`${row.id}-3`} align="left">
        {row.type}
      </TableCell>
      <TableCell key={`${row.id}-4`} align="left">
        {row.name}
      </TableCell>
      <TableCell key={`${row.id}-6`} align="left">
        {getUserEmail(organization, row.user_id)}
      </TableCell>
      <TableCell key={`${row.id}-5`} align="right">
        {row.tf_command.toUpperCase()}
      </TableCell>
      <TableCell key={`${row.id}-7`} align="right">
        {row.status}
      </TableCell>
    </TableRow>
  );
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LogViewerComponent({
  open,
  handleClose,
  log,
  currentRow,
  currentUser,
}) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [logContent, setLogContent] = useState(log);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLogContent(log);
  }, [log]);

  const refresh = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/logs/${currentRow.org_id}/${currentRow.id}/${currentRow.created.seconds}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error fetching logs", { variant: "error" });
    }
    let a = await res.text();
    setLogContent(a);
    setOpenBackdrop(false);
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {currentRow.id}
          </Typography>
          <Button autoFocus color="inherit" onClick={refresh}>
            refresh
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ background: "#fff" }}>
        <LogViewer
          hasLineNumbers={true}
          height="90vh"
          data={logContent}
          theme="light"
          toolbar={
            <PFToolbar>
              <ToolbarContent>
                <ToolbarItem>
                  <LogViewerSearch placeholder="Search value" />
                </ToolbarItem>
              </ToolbarContent>
            </PFToolbar>
          }
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </Dialog>
  );
}
