export const awsAFTSchema = {
  type: "object",
  title: "AWS Account Factory Terraform",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    ct_home_region: {
      type: "string",
      title: "Home Region of Control Tower",
      description: "something",
    },
    ct_management_account_id: {
      type: ["string", "null"],
      title: "Control Tower Account ID",
    },
    aft_management_account_id: {
      type: "string",
      title: "AWS Account Factory Management Account ID",
    },
    log_archive_account_id: {
      type: "string",
      title: "Log Archive Account ID",
    },
    audit_account_id: {
      type: "string",
      title: "Audit Account ID",
    },
    portfolio_id: {
      type: "string",
      title: "Portfolio ID",
    },
    xoogify_uids: {
      type: "array",
      title: "Hyper Cloud Automation UIDs",
      items: {
        type: "string",
        title: "uid",
      },
      default: [],
    },
    aft_feature_delete_default_vpcs_enabled: {
      type: "boolean",
      title: "Delete Default VPCs?",
      default: true,
    },
    aft_feature_cloudtrail_data_events: {
      type: "boolean",
      title: "Enable CloudTrail Data Events?",
      default: false,
    },
    aft_feature_enterprise_support: {
      type: "boolean",
      title: "Enable Enterprise Support?",
      default: false,
    },
    cloudwatch_log_group_retention: {
      type: "string",
      title: "CloudWatch Log Group Retention",
      default: "0",
    },
    aft_vpc_endpoints: {
      type: "boolean",
      title: "AFT VPC Endpoints",
      default: true,
    },
    concurrent_account_factory_actions: {
      type: "number",
      title: "Concurrent Account Factory Actions",
      default: 5,
    },
    aft_vpc_cidr: {
      type: "string",
      title: "AFT VPC CIDR",
      default: "192.168.0.0/22",
    },
    aft_vpc_private_subnet_01_cidr: {
      type: "string",
      title: "AFT VPC Private Subnet 01 CIDR",
      default: "192.168.0.0/24",
    },
    aft_vpc_private_subnet_02_cidr: {
      type: "string",
      title: "AFT VPC Private Subnet 02 CIDR",
      default: "192.168.1.0/24",
    },
    aft_vpc_public_subnet_01_cidr: {
      type: "string",
      title: "AFT VPC Public Subnet 01 CIDR",
      default: "192.168.2.0/25",
    },
    aft_vpc_public_subnet_02_cidr: {
      type: "string",
      title: "AFT VPC Public Subnet 02 CIDR",
      default: "192.168.2.128/25",
    },
    aft_metrics_reporting: {
      type: "boolean",
      title: "AFT Metrics Reporting",
      default: true,
    },
  },
  required: [
    "name",
    "ct_home_region",
    "aft_management_account_id",
    "log_archive_account_id",
    "audit_account_id",
    "portfolio_id",
  ],
};

export const awsAFTUISchema = {
  "ui:description":
    "AWS Account Factory Terraform module. You must have a Control Tower account to use this module. Please follow the steps in https://docs.aws.amazon.com/controltower/latest/userguide/quick-start.html and do steps 1-3 in https://docs.aws.amazon.com/controltower/latest/userguide/aft-getting-started.html#aft-configure-and-launch.",
  name: {
    "ui:description": "Name of the module",
  },
  ct_home_region: {
    "ui:description":
      "The region from which this module will be executed. This MUST be the same region as Control Tower is deployed.",
  },
  ct_management_account_id: {
    "ui:description":
      "Control Tower Management Account ID. This is the account ID of the account where Control Tower is deployed. If not set then the value of AWS Account ID in the parent module will be used.",
    "ui:emptyValue": null,
  },
  aft_management_account_id: {
    "ui:description":
      "AFT Management Account ID. You must create this account via Control Tower first.",
  },
  log_archive_account_id: {
    "ui:description":
      "Log Archive Account ID. This account is created by Control Tower.",
  },
  audit_account_id: {
    "ui:description":
      "Audit Account ID. This account is created by Control Tower.",
  },
  portfolio_id: {
    "ui:description":
      'Portfolio ID. This is the ID of the portfolio created by Control Tower. You can find this in the AWS Service Catalog console. Usually the name is "AWS Control Tower Account Factory Portfolio"',
  },
  xoogify_uids: {
    "ui:description":
      "List of Hyper Cloud Automation UIDs that will be able to assume the AWSAFTAdmin role",
    items: {
      "ui:description": "Hyper cloud automation UID",
    },
  },
  aft_feature_delete_default_vpcs_enabled: {
    "ui:description": "Feature flag toggling deletion of default VPCs on/off",
  },
  aft_feature_cloudtrail_data_events: {
    "ui:description": "Feature flag toggling CloudTrail Data Events on/off",
  },
  aft_feature_enterprise_support: {
    "ui:description": "Feature flag toggling Enterprise Support on/off",
  },
  cloudwatch_log_group_retention: {
    "ui:description":
      "Amount of days to keep CloudWatch Log Groups for Lambda functions. 0 = Never Expire",
  },
  aft_vpc_endpoints: {
    "ui:description": "Flag turning VPC endpoints on/off for AFT VPC",
  },
  concurrent_account_factory_actions: {
    "ui:description":
      "Maximum number of accounts that can be provisioned in parallel.",
  },
  aft_vpc_cidr: {
    "ui:description": "CIDR Block to allocate to the AFT VPC",
  },
  aft_vpc_private_subnet_01_cidr: {
    "ui:description": "CIDR Block to allocate to the Private Subnet 01",
  },
  aft_vpc_private_subnet_02_cidr: {
    "ui:description": "CIDR Block to allocate to the Private Subnet 02",
  },
  aft_vpc_public_subnet_01_cidr: {
    "ui:description": "CIDR Block to allocate to the Public Subnet 01",
  },
  aft_vpc_public_subnet_02_cidr: {
    "ui:description": "CIDR Block to allocate to the Public Subnet 02",
  },
  aft_metrics_reporting: {
    "ui:description": "Flag toggling reporting of operational metrics",
  },
};
