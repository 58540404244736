import React from "react";
import { useState, useEffect } from "react";
import { styled, alpha } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import { TemplateCloudModule } from "./TemplateCloudModule";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

import { Form } from "@rjsf/mui";
import {
  ArrayFieldTemplateX,
  ObjectFieldTemplateX,
} from "./common/rjsfTemplates";
import { TranslatableString, replaceStringParameters } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";
import { gcpResourceConfigs } from "./gcpResourceDefintions";
import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { updateTemplateKeyValue } from "../utils/db";
import { apiCall, getModuleName } from "../utils/api";
import { db } from "../firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";

// This customized TreeItem to add dotted lines to the left when the tree is expanded
const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.group}`]: {
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    borderLeft: `1px dotted ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

export default function ArchitectureTemplate({
  templateId,
  organization,
  getTemplateNamesForOrg,
}) {
  const [node, setNode] = useState({});
  const [expanded, setExpanded] = useState([]);
  const [expandCollapseTree, setExpandCollapseTree] = useState(true);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [openCopyTemplateDialog, setOpenCopyTemplateDialog] = useState(false);
  const [copyTemplateName, setCopyNewTemplate] = useState(""); // This holds the name of the new template
  const [template, setTemplate] = useState({
    name: "",
    architecture: {},
    id: null,
    description: "",
    details: "",
  });
  const [openPublishDialog, setOpenPublishDialog] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [globalVariables, setGlobalVariables] = useState({}); // This holds the global variables for the organization
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unsubscribe = null;
    if (templateId) {
      getTemplate();
      setExpanded([]);
      setExpandCollapseTree(true);
      unsubscribe = onSnapshot(
        doc(
          db,
          `organizations/${organization.id}/templates/${templateId}/modules/1`
        ),
        (doc) => {
          const node_data = doc.data();
          if (node_data === undefined) {
            // Node data is undefined probably the node is deleted
            return;
          }
          setGlobalVariables(
            node_data.properties?.globals ? node_data.properties.globals : {}
          );
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
    // eslint-disable-next-line
  }, [templateId, organization.id]);

  const copyTemplate = async () => {
    setOpenBackdrop(true);
    if (!copyTemplateName) {
      alert("The template name can not be an empty sting");
      return;
    }
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/copy/${organization.id}/${templateId}`,
      "POST",
      {
        new_name: copyTemplateName,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, { variant: "error" });
    }
    if (res.status === 200) {
      const data = await res.json();
      enqueueSnackbar(`Succesfully added ${data.name} with id ${data.id}`, {
        variant: "success",
      });
      setCopyNewTemplate("");
      getTemplateNamesForOrg(organization.id);
      setOpenCopyTemplateDialog(false);
    }
    setOpenBackdrop(false);
  };

  const collectIds = (node) => {
    const ids = [node.id];

    for (const child of node.children) {
      ids.push(...collectIds(child));
    }

    return ids;
  };

  const expandTheTree = () => {
    let nodes = template.architecture;
    const ids = collectIds(nodes);
    setExpanded(ids);
    setExpandCollapseTree(false);
  };

  const collapseTheTree = () => {
    setExpanded([]);
    setExpandCollapseTree(true);
  };

  const refreshNodes = async () => {
    await getTemplate();
  };

  const getTemplate = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/tree/${organization.id}/${templateId}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error getting template tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setTemplate(data);
    }
    setOpenBackdrop(false);
  };

  const handleToggle = (event, nodeIds) => {
    event.preventDefault();
    // This code allows the tree to be expanded only when icon or name of the GCPResource are clicked
    if (event.target.getAttribute("data-allow-toggle")) {
      setExpanded(nodeIds);
    }
  };

  const onSubmit = async ({ formData }, e) => {
    if (!isAdmin()) {
      alert("Only admins can update the module settings");
      return;
    }
    const properties = formData;
    const modified_at = new Date().getTime();
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/node/${organization.id}/${templateId}/${node.id}`,
      "POST",
      {
        properties,
        modified_at,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error updating the node in the tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      enqueueSnackbar(`Node updated successfully`, { variant: "success" });
    }
    setOpenBackdrop(false);
    setOpenConfigDialog(false);
  };

  const sendNodeUpstream = (node) => {
    if (node?.type === "organization" && node?.properties?.globals) {
      let sortedKeys = Object.keys(node.properties.globals)
        .sort()
        .reduce((r, k) => {
          r[k] = node.properties.globals[k];
          return r;
        }, {});
      node.properties.globals = sortedKeys;
    }

    setNode(node);
    setOpenConfigDialog(true);
  };

  const removeNode = async (nodeToRemove) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/node/${organization.id}/${templateId}/${nodeToRemove.id}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error removing node from the tree: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      await getTemplate();
    }
    setOpenBackdrop(false);
    return;
  };

  const isAdmin = () => {
    if (!organization || organization === undefined) {
      return false;
    }
    return organization.admin.id === currentUser.uid;
  };

  const renderTree = (nodes) => (
    <StyledTreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={
        <TemplateCloudModule
          key={nodes.id}
          node={nodes}
          removeNode={removeNode}
          refreshNodes={refreshNodes}
          sendNodeUpstream={sendNodeUpstream}
          isAdmin={isAdmin}
          globalVariables={globalVariables}
          organization={organization}
          templateId={templateId}
        />
      }
    >
      {Array.isArray(nodes.children)
        ? nodes.children.map((node) => renderTree(node))
        : null}
    </StyledTreeItem>
  );

  const displayArchtecture = () => {
    if (!template) {
      return <p>Not found</p>;
    }
    let r =
      Object.keys(template.architecture).length === 0 ? (
        <p>Not found</p>
      ) : (
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon data-allow-toggle />}
          defaultExpandIcon={<ChevronRightIcon data-allow-toggle />}
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={{
            // height: 500,
            flexGrow: 1,
            maxWidth: "80%",
            overflowY: "auto",
          }}
        >
          {renderTree(template.architecture)}
        </TreeView>
      );
    return r;
  };

  const handlePublish = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/blueprint/publish`,
      "POST",
      {
        blueprint: template,
        organization_id: organization.id,
        template_id: templateId,
      },
      currentUser
    );
    if (res.status !== 200) {
      const error = await res.text();
      enqueueSnackbar(error, { variant: "error" });
    }
    if (res.status === 200) {
      const success = await res.json();
      enqueueSnackbar(success.message, { variant: "success" });
    }
    setOpenPublishDialog(false);
    setOpenBackdrop(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="left">
        <Box display="flex" flexDirection="column" m={1}>
          <Box display="flex" m={1} flexDirection="column">
            <LargeTextEditBox
              label="description"
              value={template.description}
              organization_id={organization.id}
              template_id={templateId}
              isAdmin={isAdmin}
            />
            <LargeTextEditBox
              label="details"
              value={template.details}
              organization_id={organization.id}
              template_id={templateId}
              isAdmin={isAdmin}
            />
          </Box>
          <Box m={1}>
            <Button
              variant="contained"
              color="primary"
              onClick={expandCollapseTree ? expandTheTree : collapseTheTree}
            >
              {expandCollapseTree
                ? "Expand architecture"
                : "Collapse architecture"}
            </Button>
            {isAdmin() && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenCopyTemplateDialog(true)}
                sx={{ ml: 1 }}
              >
                Copy Architecture
              </Button>
            )}
            {isAdmin() && (
              <Button
                onClick={() => setOpenPublishDialog(true)}
                variant="contained"
                color="primary"
                sx={{ ml: 1 }}
              >
                Publish Architecture
              </Button>
            )}
            <Dialog
              open={openCopyTemplateDialog}
              onClose={() => setOpenCopyTemplateDialog(false)}
              aria-labelledby="form-dialog-title"
              maxWidth="lg"
              //fullWidth={true}
            >
              <DialogTitle id="form-dialog-title">
                Copy architecture {template.name}
              </DialogTitle>
              <DialogContent>
                <TextField
                  variant="standard"
                  error={!copyTemplateName ? true : false}
                  margin="normal"
                  id="copyTemplateName"
                  label="New Architecture Name"
                  value={copyTemplateName}
                  fullWidth
                  onChange={(e) => setCopyNewTemplate(e.target.value)}
                  inputProps={{ pattern: "[a-zA-Z0-9]{2,15}" }}
                  helperText="must match regex [a-zA-Z0-9]{2,15}"
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={() => setOpenCopyTemplateDialog(false)}>
                  Disagree
                </Button>
                <Button onClick={copyTemplate} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={openPublishDialog}
              onClose={() => setOpenPublishDialog(false)}
            >
              <DialogTitle>Publish Template</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {`Are you sure you want to publish/update template ${template.name} (ID: ${template.id}) to global
                blueprints?`}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenPublishDialog(false)}>
                  Cancel
                </Button>
                <Button onClick={() => handlePublish()} color="primary">
                  Publish
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        <Box display="flex" m={1}>
          {displayArchtecture()}
          <ModuleSettingsDialog
            node={node}
            openConfigDialog={openConfigDialog}
            setOpenConfigDialog={setOpenConfigDialog}
            onSubmit={onSubmit}
            organizationId={organization.id}
            templateId={templateId}
          />
        </Box>
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1000 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export function ModuleSettings({ node, onSubmit, closeDialog }) {
  useEffect(() => {
    // This code is necessary to prevent the ResizeObserver error
    // source: https://github.com/mui/material-ui/issues/36909
    // Why is that error showing up? I don't know.
    // Why is this fix working? I don't know.
    // Is this a hack? Yes.
    // God help us all with the next version of Material UI.
    window.addEventListener("error", (e) => {
      //console.log(e.message);
      if (
        e.message === "ResizeObserver loop limit exceeded" ||
        e.message ===
          "ResizeObserver loop completed with undelivered notifications."
      ) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);
  return (
    <Form
      schema={gcpResourceConfigs[node.type].schema}
      formData={node.hasOwnProperty("properties") ? node.properties : {}}
      onSubmit={onSubmit}
      uiSchema={
        "uiSchema" in gcpResourceConfigs[node.type]
          ? gcpResourceConfigs[node.type].uiSchema
          : {}
      }
      validator={validator}
      templates={{
        ArrayFieldTemplate: ArrayFieldTemplateX,
        ObjectFieldTemplate: ObjectFieldTemplateX,
      }}
      translateString={(str, params) => {
        switch (str) {
          case TranslatableString.NewStringDefault:
            return ""; // Use an empty string for the new additionalProperties string default value
          case TranslatableString.KeyLabel:
            return replaceStringParameters("%1", params);
          default:
            return str;
        }
      }}
    >
      {
        <Box sx={{ m: 1 }}>
          <Button type="submit" variant="contained" color="primary">
            Save
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={closeDialog}
            sx={{ ml: 1 }}
          >
            Cancel
          </Button>
        </Box>
      }
    </Form>
  );
}

export function ModuleSettingsDialog({
  node,
  openConfigDialog,
  setOpenConfigDialog,
  onSubmit,
  organizationId,
  templateId,
}) {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [downloadedNode, setDownloadedNode] = useState({});
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    if (openConfigDialog) {
      getNodeDetails();
    }
    // eslint-disable-next-line
  }, [openConfigDialog]);

  const getNodeDetails = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/node/${organizationId}/${templateId}/${node.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.text();
      enqueueSnackbar(`Error fetching node ${node.id}: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setDownloadedNode(data);
    }
    setOpenBackdrop(false);
  };
  return (
    <>
      <Dialog
        open={openConfigDialog}
        onClose={() => setOpenConfigDialog(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="lg"
        //fullWidth
        //fullScreen
      >
        <AppBar sx={{ position: "sticky" }} component="nav">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setOpenConfigDialog(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {Object.keys(node).length > 0 && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {gcpResourceConfigs[node.type].displayName}
              </Typography>
            )}
            {Object.keys(node).length > 0 && (
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {getModuleName(node)}
              </Typography>
            )}
          </Toolbar>
        </AppBar>
        <DialogActions>
          {Object.keys(downloadedNode).length > 0 && (
            <ModuleSettings
              node={downloadedNode}
              onSubmit={onSubmit}
              closeDialog={() => setOpenConfigDialog(false)}
            />
          )}
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

function LargeTextEditBox({
  label,
  value,
  organization_id,
  template_id,
  isAdmin,
}) {
  const [editText, setEditText] = useState(false);
  const [newTextValue, setNewTextValue] = useState(value);

  useEffect(() => {
    setNewTextValue(value);
  }, [value]);

  const applyChangeName = async () => {
    await updateTemplateKeyValue(
      organization_id,
      template_id,
      label,
      newTextValue
    );
    setEditText(false);
  };

  const handleCancelChangeName = () => {
    setEditText(false);
    setNewTextValue(value);
  };

  return (
    <Box
      display="flex"
      //alignItems="center"
      flexDirection="column"
    >
      <Typography ml={1} variant="h6">
        {label.charAt(0).toUpperCase() + label.slice(1)}:
      </Typography>
      <Box
        flex
        onClick={() => (isAdmin() ? setEditText(true) : setEditText(false))}
      >
        {!editText &&
          (newTextValue ? (
            <Box ml={1} sx={{ whiteSpace: "pre-wrap" }}>
              <Typography component="div" variant="subtitle1">
                {newTextValue}
              </Typography>
            </Box>
          ) : (
            <Typography ml={1} variant="subtitle1">
              <br />
            </Typography>
          ))}
        {editText && (
          <TextField
            defaultValue={newTextValue}
            onChange={(e) => setNewTextValue(e.target.value)}
            multiline
            fullWidth
            rows={4}
            inputProps={{ style: { whiteSpace: "pre-wrap" } }}
          />
        )}
      </Box>
      {editText && (
        <Box flex>
          {editText && (
            <IconButton onClick={() => applyChangeName()}>
              <CheckIcon />
            </IconButton>
          )}
          {editText && (
            <IconButton onClick={() => handleCancelChangeName(false)}>
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
}
